@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: rgb(245, 245, 245) !important;
}

.dot {
  margin: 0 10px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}

a:hover {
  text-decoration: none !important;
}

.card-title {
  color: #000;
}
